import { getAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { AddressZero } from "@ethersproject/constants";
import { getContractCfg } from "../config/contract";
import { setHttpToken } from "./httpToken";
import dayjs from "dayjs";

/**
 * 是否是生产环境
 * @returns
 */
export function isPrd() {
  const mockPrd = `${localStorage.getItem("prd")}` === "1";
  const env = process.env.REACT_APP_ENV;
  return mockPrd || env === "production";
}

/**
 * 16进制数值转 10 进制数值
 * @param {*} hexadecimal hex num or hex string
 * @returns
 */
export function hexToDecimal(hexadecimal: number | string) {
  let result = NaN;
  if (typeof hexadecimal === "number") {
    result = parseInt(`${hexadecimal}`);
  } else if (typeof hexadecimal === "string") {
    result = parseInt(hexadecimal, 16);
  }
  return result;
}

/**
 * 10进制数值转 16 进制数值
 */
export function decimalToHex(decimal: number) {
  return `0x${Number(decimal).toString(16)}`;
}

/**
 * 邀请链接
 * @returns
 */
export function getInviteUrl() {
  const logInfo = getLoginInfo();
  return `${window.location.origin}/market/invite/${logInfo?.code}`;
}

/**
 * 设置用户登陆信息，登陆，注册,退出登录都有调用
 * @param data
 * @param clean 退出登录时为 true
 */
export function setLoginInfo(data: any, clean = false) {
  if (clean) {
    localStorage.removeItem("loginUserInfo");
    localStorage.removeItem("platformsData");
    // 清理登录态;
    setHttpToken("");
  } else {
    localStorage.setItem("loginUserInfo", JSON.stringify(data));
    localStorage.setItem("platformsData", JSON.stringify(data?.platformsData));
  }
}

/**
 * 获取登录信息
 * @returns
 */
export function getLoginInfo() {
  const loginUserInfo = localStorage.getItem("loginUserInfo");
  const str =
    loginUserInfo === "undefined"
      ? "{}"
      : localStorage.getItem("loginUserInfo") || "{}";
  return JSON.parse(str);
}

/**
 * 是否是 email 地址
 * @param str
 * @returns
 */
export function isEmail(str: string) {
  const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return reg.test(str);
}

export function isAddress(value: string) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

/**
 * 8-20字符密码
 * @param str
 * @returns
 */
export function isPwd(str: string) {
  const reg = /^.{8,20}$/;
  return reg.test(str);
}

// account is not optional
export function getSigner(library: any, account: string) {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: any, account: string) {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: any,
  account: string
) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

/**
 * 获取钱包地址的掩码
 * @param address 钱包地址
 * @param startNum 保留前几位
 * @param endNum 保留后几位
 * @param sign 掩码符号
 * @returns
 */
export function getShowAddress(
  address: string = "",
  startNum = 6,
  endNum = 4,
  sign = "..."
) {
  if (!address) {
    return "-";
  }
  const startStr = address?.slice(0, startNum);
  const endStr = address?.slice(address?.length - endNum);
  const result = `${startStr}${sign}${endStr}`;
  return result;
}

/**
 * 获取默认的链 id
 */
export function getDefaultChainId() {
  const { chains } = getContractCfg();
  return chains?.[0]?.id;
}

/**
 * 获取 chainId 对应的 chain name
 * @param id chainId
 */
export function getChainNameById(id?: number) {
  const { chains } = getContractCfg();
  const curChain = chains.find((item) => {
    return item.id === id;
  });
  return curChain?.name || "";
}

export function getIsMobi() {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
}

/**
 * 是否安装了 ok 钱包或者小狐狸
 * @returns true 已安装，false没安装
 */
export function checkNoInstallWallet() {
  let hadWallet = false;
  if (typeof (window as any).okxwallet !== "undefined") {
    hadWallet = true;
  } else if (typeof window.ethereum !== "undefined") {
    hadWallet = true;
  }
  return hadWallet;
}

/**
 * 是否在 okx 钱包 app 里面
 * @returns
 */
export function isInOKWalletApp() {
  return getIsMobi() && typeof (window as any).okxwallet !== "undefined";
}

/**
 * Metamas deep link
 */
export function jumpToMetamaskApp() {
  const dappUrl = `${window.document.domain}/`;
  window.location.href = `https://metamask.app.link/${dappUrl}`;
}

/**
 * okx wallet deep link
 */
export function jumpToOKApp() {
  const dappUrl = `${window.location.origin}/`;
  const encodedUrl =
    "https://www.okx.com/download?deeplink=" +
    encodeURIComponent(
      "okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(dappUrl)
    );
  window.location.href = encodedUrl;
}

export function getQuarterlyName(timestamp: any) {
  const date = new Date(timestamp * 1000);

  // 获取年份、月份（0-11，1月为0，12月为11），和日期
  let year = date.getFullYear();
  let month = date.getMonth(); // 0-11，0是1月，11是12月
  const day = date.getDate();  // 1-31

  // 定义从8月15日开始的季度
  if (month < 7 || (month === 7 && day < 15)) {
      // 如果在8月15日之前，则属于上一年度的最后一个季度
      year -= 1;
      month += 12; // 处理为上一年的月份（如7月为19，即8月起始计算）
  }

  // 如果日期在月的15号之前，视为上一个月
  if (day < 15) {
      month -= 1;
  }

  // 计算季度：8月为第一个月
  const adjustedMonth = month - 7; // 调整8月为第0个月
  const quarter =  ((year - 2024)*4)+Math.floor(adjustedMonth / 3) + 1; // 每个季度包含3个月

  // 计算该月在季度中的位置（1-3）
  const monthInQuarter = (adjustedMonth % 3) + 1;

  console.log(`当前处于 ${year} 年第 ${quarter} 季度的第 ${monthInQuarter} 个月`);
  return {
    quarter,
    monthInQuarter
  };
}

function getQuarterlyEndDay(endDate: any) {
  if (new Date().getTime() > endDate.valueOf()) {
    return endDate.add(1, 'day').format("YYYY-MM-DD")
  }
}

export function getMonthSection(startDate = "2025-01-15") {
  // getQuarterlyName(new Date("2025-01-15").getTime() / 1000)
  // let startDate = ;
  const start = dayjs(startDate);
  // 计算下个月的同一天
  const end = start.add(1, 'month').subtract(1, 'day');
  const m2Start = end.add(1, 'day');
  const m2End = m2Start.add(1, 'month').subtract(1, 'day');
  const m3Start = m2End.add(1, 'day');
  const m3End = m3Start.add(1, 'month').subtract(1, 'day');
  // 打印日期区间
  const m1 = start.format('YYYY-MM-DD') + " — " + end.format('YYYY-MM-DD');
  const m2 = m2Start.format('YYYY-MM-DD') + " — " + m2End.format('YYYY-MM-DD');
  const m3 = m3Start.format('YYYY-MM-DD') + " — " + m3End.format('YYYY-MM-DD');
  const quarterStart = getQuarterlyEndDay(m3End);
  return {
    months: [m1, m2, m3],
    quarterStart
  }
}

export function dataFormat(data: number) {
  return data >= 1000 ? `${(data / 1000).toFixed(1)}k` : data
}
